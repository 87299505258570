import {
  getSettings,
  updateSettings,
  getRateCardMapping,
  getRateCardMappingRow
} from '@/services/settings-api.service.js'

const getDefaultState = () => {
  return {
    isLoaded: false,
    boardId: null,
    ceoPersonId: null,
    hrLeadPersonId: null,
    hiringRequestReviewers: [],
    planApprovers: null,
    performanceAccessibleByHROnly: false,
    baseCurrency: 'USD',
    nextJobCode: '0',
    isRestrictToTeamViewEnabled: false,
    forecastSheetId: null,
    revenueAttributionData: null,
    isGoogleSheetsAutoSyncOn: false,
    isPlanStrictCheckEnabled: false,
    lockSubmittedPlan: false,
    formulas: [],
    reviewers: [],
    organizationUnits: {},
    fieldVisibility: {},
    hubTheme: null,
    stateOptions: {},
    rateCardAttributeColumns: [],
    rateCardMapping: {},
    rateCardSalaryColumn: null
  }
}

const state = getDefaultState()

const getters = {
  isGoogleSheetsAutoSyncOn: (_state) => {
    return _state.isGoogleSheetsAutoSyncOn
  },
  preferredBaseCurrency: (_state) => {
    return _state.baseCurrency || 'USD'
  },
  loaded: (_state) => {
    return _state.isLoaded
  },
  nextJobCode: (_state) => {
    return _state.nextJobCode
  },
  ceoPersonId: (_state) => {
    return _state.ceoPersonId
  },
  forecastSheetId: (_state) => {
    return _state.forecastSheetId
  },
  revenueAttributionData: (_state) => {
    return _state.revenueAttributionData
  },
  planApprovers: (_state) => {
    return _state.planApprovers
  },
  isPlanStrictCheckEnabled: (_state) => {
    return _state.isPlanStrictCheckEnabled ?? false
  },
  isLockSubmittedPlanEnabled: (_state) => {
    return _state.lockSubmittedPlan ?? false
  },
  planApproversFlat: (_state) => {
    return Object.values(_state.planApprovers || {}).flat()
  },
  formulas: (_state) => {
    return _state.formulas || []
  },
  reviewers: (_state) => {
    return _state?.reviewers || []
  },
  planReviewers: (_state) => (boardId) => {
    return _state[boardId]?.reviewers || []
  },
  isReviewer:
    (_state) =>
    ({ uid, boardId }) =>
      _state.reviewers?.find((reviewer) => reviewer.uid === uid) ||
      _state[boardId]?.reviewers?.find((reviewer) => reviewer.uid === uid),
  organizationUnits: (_state) => {
    return _state.organizationUnits
  },
  getFieldVisibility: (_state) => {
    return _state.fieldVisibility
  },
  getHubTheme: (_state) => {
    return _state.hubTheme || {}
  },
  stateOptions: (_state) => {
    return _state.stateOptions || {}
  },
  rateCardMapping: (_state) => {
    return _state.rateCardMapping || {}
  },
  rateCardAttributeColumns: (_state) => {
    return _state.rateCardAttributeColumns || []
  },
  rateCardSalaryColumn: (_state) => {
    return _state.rateCardSalaryColumn || null
  }
}

const mutations = {
  setSettings(_state, settings) {
    if (!settings) return
    _state = Object.assign(_state, settings)
  },
  setPlanSettings(_state, { boardId, settings }) {
    if (!settings) return
    if (!_state[boardId]) {
      _state[boardId] = getDefaultState()
    }
    _state[boardId] = Object.assign(_state[boardId], settings)
  },
  resetSettings(_state) {
    Object.assign(_state, getDefaultState())
  },
  setForecastSheetId(_state, forecastSheetId) {
    _state.forecastSheetId = forecastSheetId
  },
  setPlanApprovers(_state, approvers) {
    _state.planApprovers = approvers
  },
  setPlanStrictCheck(_state, strictCheck) {
    _state.isPlanStrictCheckEnabled = strictCheck
  },
  setLockSubmittedPlan(_state, lockSubmittedPlan) {
    _state.lockSubmittedPlan = lockSubmittedPlan
  },
  setRevenueAttributionData(_state, revenueAttributionData) {
    _state.revenueAttributionData = revenueAttributionData
  },
  setIsLoaded(_state, isLoaded) {
    _state.isLoaded = isLoaded
  },
  addOrUpdateFormula(_state, formula) {
    if (_state.formulas === null) _state.formulas = []

    const index = _state.formulas.findIndex((f) => f.name === formula.name)
    if (index === -1) {
      _state.formulas.push(formula)
    } else {
      _state.formulas[index] = formula
    }
  },
  removeFormula(_state, formulaName) {
    const index = _state.formulas.findIndex((f) => f.name === formulaName)
    if (index !== -1) {
      _state.formulas.splice(index, 1)
    }
  },

  setReviewers(_state, reviewers) {
    _state.reviewers = reviewers
  },
  setOrganizationUnits(_state, organizationUnits) {
    _state.organizationUnits = organizationUnits
  },
  setFieldVisibility(_state, fieldVisibility) {
    _state.fieldVisibility = fieldVisibility
  },
  setHubColor(_state, { attribute, color }) {
    _state.hubTheme = state.hubTheme ?? {}
    _state.hubTheme[attribute] = color
  },
  setStateOptions(_state, stateOptions) {
    _state.stateOptions = stateOptions
  },
  setRateCardMapping(_state, rateCardMapping) {
    _state.rateCardMapping = rateCardMapping
  },
  setRateCardAttributeColumns(_state, rateCardAttributeColumns) {
    _state.rateCardAttributeColumns = rateCardAttributeColumns
  },
  setRateCardSalaryColumn(_state, rateCardSalaryColumn) {
    _state.rateCardSalaryColumn = rateCardSalaryColumn
  }
}

const actions = {
  resetSettings(context) {
    context.commit('resetSettings')
  },
  async setBaseCurrency(context, { baseCurrency, boardId }) {
    if (!boardId) throw new Error('BoardId is missing')
    try {
      await updateSettings({ boardId, settings: { baseCurrency } })
      context.commit('setSettings', { baseCurrency })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },
  async fetchPlanSettings(context, { boardId }) {
    try {
      const settings = await getSettings({ boardId })
      context.commit('setPlanSettings', { boardId, settings })
    } catch (e) {
      if (!e.message.includes('Missing or insufficient permissions')) console.error(e)
    }
  },
  async fetchSettings(context, { boardId }) {
    try {
      const settings = await getSettings({ boardId })
      context.commit('setSettings', settings)
    } catch (e) {
      if (!e.message.includes('Missing or insufficient permissions')) console.error(e)
    } finally {
      context.commit('setIsLoaded', true)
    }
  },
  async setForecastSheetId(context, { forecastSheetId, boardId }) {
    try {
      context.commit('setForecastSheetId', forecastSheetId)

      await updateSettings({ boardId, settings: { forecastSheetId } })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },
  async setPlanApprovers(context, { planApprovers, boardId }) {
    try {
      context.commit('setPlanApprovers', planApprovers)

      await updateSettings({ boardId, settings: { planApprovers } })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong while setting approvers to board settings')
    }
  },
  async setPlanStrictCheck(context, { strickCheck, boardId }) {
    try {
      context.commit('setPlanStrictCheck', strickCheck)

      await updateSettings({ boardId, settings: { isPlanStrictCheckEnabled: strickCheck } })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong while setting strict check to board settings')
    }
  },

  async toggleLockSubmittedPlan(context, { lockSubmittedPlan, boardId }) {
    try {
      context.commit('setLockSubmittedPlan', lockSubmittedPlan)

      await updateSettings({ boardId, settings: { lockSubmittedPlan } })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong while setting lock scenarios to board settings')
    }
  },
  async setRevenueAttributionData(context, { revenueAttributionData, boardId }) {
    try {
      context.commit('setRevenueAttributionData', revenueAttributionData)
      await updateSettings({ boardId, settings: { revenueAttributionData } })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },
  async setFormula(context, { formula, boardId }) {
    try {
      context.commit('addOrUpdateFormula', formula)
      await updateSettings({ boardId, settings: { formulas: context.getters.formulas } })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },
  async removeFormula(context, { formulaName, boardId }) {
    try {
      context.commit('removeFormula', formulaName)
      await updateSettings({ boardId, settings: { formulas: context.getters.formulas } })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },

  async saveGoogleAutoSyncSetting(context, { isGoogleSheetsAutoSyncOn, boardId }) {
    context.commit('isGoogleSheetsAutoSyncOn', isGoogleSheetsAutoSyncOn)
    await updateSettings({
      boardId,
      settings: { isGoogleSheetsAutoSyncOn: context.getters.isGoogleSheetsAutoSyncOn }
    })
  },

  async setReviewers(context, { reviewers, boardId }) {
    try {
      context.commit('setReviewers', reviewers)
      await updateSettings({ boardId, settings: { reviewers: context.getters.reviewers } })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },

  async setPlanReviewers(context, { reviewers, boardId }) {
    try {
      context.commit('setPlanReviewers', reviewers)
      await updateSettings({
        boardId,
        settings: { reviewers: context.getters.planReviewers(boardId) }
      })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },

  async setOrganizationUnits(context, { organizationUnits, boardId }) {
    try {
      context.commit('setOrganizationUnits', organizationUnits)
      await updateSettings({
        boardId,
        settings: { organizationUnits: context.getters.organizationUnits }
      })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong saving')
    }
  },

  async saveFieldVisibility(context, { fieldVisibility, boardId }) {
    try {
      context.commit('setFieldVisibility', fieldVisibility)
      await updateSettings({
        boardId,
        settings: { fieldVisibility: context.getters.getFieldVisibility }
      })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong when saving field visibility')
    }
  },

  async setHubColor(context, { boardId, attribute, color }) {
    context.commit('setHubColor', { attribute, color })

    await updateSettings({
      boardId,
      settings: { hubTheme: { ...context.getters.getHubTheme, [attribute]: color } }
    })
  },

  async setStateOptions(context, { stateOptions, boardId }) {
    try {
      context.commit('setStateOptions', stateOptions)
      await updateSettings({
        boardId,
        settings: { stateOptions: context.getters.stateOptions }
      })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong when saving state options')
    }
  },

  async setRateCardAttributeColumns(context, { rateCardAttributeColumns, boardId }) {
    try {
      context.commit('setRateCardAttributeColumns', rateCardAttributeColumns)
      await updateSettings({
        boardId,
        settings: { rateCardAttributeColumns: context.getters.rateCardAttributeColumns }
      })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong when saving rate card columns')
    }
  },

  async fetchRateCardMapping(context, { boardId }) {
    try {
      const rateCardMapping = await getRateCardMapping({ boardId })

      if (rateCardMapping?.success && rateCardMapping?.data?.columns) {
        rateCardMapping.data.rateCardMapping = rateCardMapping.data.rateCardMapping.map((row) => {
          return rateCardMapping.data.columns.reduce((orderedRow, column) => {
            orderedRow[column] = row[column]
            return orderedRow
          }, {})
        })
      }

      context.commit('setRateCardMapping', rateCardMapping)
    } catch (e) {
      console.error(e)
    }
  },

  async getRateCardMappingRow(_, { boardId, attributeColumnValues }) {
    try {
      const rateCardMapping = await getRateCardMappingRow({ boardId, attributeColumnValues })
      return rateCardMapping.data
    } catch (e) {
      console.error(e)
    }
  },

  async setRateCardSalaryColumn(context, { rateCardSalaryColumn, boardId }) {
    try {
      context.commit('setRateCardSalaryColumn', rateCardSalaryColumn)
      await updateSettings({
        boardId,
        settings: { rateCardSalaryColumn: context.getters.rateCardSalaryColumn }
      })
    } catch (e) {
      console.error(e)
      throw new Error('Something went wrong when saving rate card salary column')
    }
  }
}

export default {
  state,
  mutations,
  getters,
  actions,
  modules: {}
}
